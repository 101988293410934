.horizontalContainer {
    height: 2rem;
}

.actionTitle {
    color: var(--accentColor);
}

.deleted {
    text-decoration: line-through;
}

.historyItem {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 4pt;
}

.historyItem:hover {
    background-color: #f7fafc;
}